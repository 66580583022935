<script>
import {contactService} from "@/helpers/fakebackend/contact.service";
import Swal from "sweetalert2";
import {requestMessage} from "@/helpers/request-message";
import {userService} from "@/helpers/fakebackend/user.service";

export default {
  name: "contact-person",
   data() {
    return {
      spinnerShow: false,
      contacts: [],
      listView: true,
      users: [],
    };
  },
  created() {
    const self = this;
    contactService.getAll().then((data)=>{
      self.contacts = data.content;
      self.contacts.forEach((contact)=>{
        contact.disabled = true;
      });
    });
    userService.getAll().then((data)=>{
      self.users = data.content;
    });
  },
  methods: {
    onSaveContact(event, contact) {
      event.preventDefault();
      if (contact.disabled){
        contact.disabled = false;
        this.$forceUpdate();
        return;
      }
      contact.loading = true;
      this.$forceUpdate();

      let request = null;
      if (contact.uuid)
        request = contactService.updateContact(contact)
      else
        request = contactService.addContact(contact)
      request.then((data)=>{
        if (data.content)
          contact.uuid = data.content.uuid;
        contact.disabled = true;
        contact.loading = false;
        Swal.fire('Kontakt erfolgreich gespeichert', '', 'success');
        this.$forceUpdate();
      }).catch((data)=>{
        contact.loading = false;
        Swal.fire(requestMessage[data.code].de, '', 'warning');
        this.$forceUpdate();
      });
    },
    onRemoveContact(e, contact){
      e.preventDefault();
      contact.loading = true;
      this.$forceUpdate();
      Swal.fire({
        title: "Sie sind sicher?",
        text:"Das Löschen eines Kontaktes ist unwiderruflich",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00e200",
        cancelButtonColor: "#ff3d60",
        confirmButtonText: "Ja",
        cancelButtonText: "Nein"
      }).then(result => {
        if (result.value) {
          if (contact.uuid){
            contactService.deleteContact(contact)
                .then(()=>this.contacts = this.removeItemFromArray(this.contacts, contact))
                .catch((data)=>{
                  const message = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
                  Swal.fire(message, '', 'warning');
                  contact.loading = false;
                  this.$forceUpdate();
                })
          }else
            this.contacts = this.removeItemFromArray(this.contacts, contact)
        }else{
          contact.loading = false;
          this.$forceUpdate();
        }
      });
    },
    onCloseContact(e, contact){
      e.preventDefault();
      this.contacts = this.removeItemFromArray(this.contacts, contact)
      if (this.contacts.length === 0)
        this.listView = true;
    },
    removeItemFromArray(array, item){
      const tempContacts = [];
      for (let i=0; i < array.length; i++){
        if (item !== array[i]){
          tempContacts.push(array[i]);
        }
      }
      return tempContacts;
    },
    addContact(email, name, isBilling, isTech, isSales){
      this.listView = false;
      const tempContactList = [{
        name: name??"",
        phone: "",
        email: email??"",
        isBilling: isBilling??0,
        isTech: isTech??0,
        isSales: isSales??0,
        success: null,
        error: null,
      }];
      this.contacts = tempContactList.concat(this.contacts);
    },
    transferExistingContact(){
      const customer = JSON.parse(localStorage.getItem("customer_infos"));
      if (this.users.length <= 1){
        this.addContact(customer.email, customer.name, 1, 1, 1);
      } else {
        for (let i=0; i < this.users.length; i++) {
          const user = this.users[i];
          if (user.email !== customer.email) {
            this.addContact(user.email, user.name, 1, 1, 1);
            break;
          }
        }
      }
    },
    editContacts(){
      this.listView = false;
    },
    changeContactView(e){
      e.preventDefault();
      if (this.contacts.length === 0)
        this.listView = true
      else
        this.listView = !this.listView;
    },
  },
};
</script>

<template>
  <div>
    <b-dropdown variant="primary" toggle-class="pl-1 pt-1" class="btn-plus mt-4">
      <template slot="button-content">
        <span><i class="dripicons-plus"></i></span>hinzufügen
      </template>
      <b-dropdown-item v-on:click="()=>this.addContact()">Neuer Kontakt</b-dropdown-item>
      <b-dropdown-item v-on:click="transferExistingContact" v-show="contacts.length === 0">Bestehenden Kontakt übernehmen</b-dropdown-item>
    </b-dropdown>
    <b-overlay :show="spinnerShow" rounded="sm">
      <div class="text-right">
        <a :class="!listView?'text-primary':''" href="#" v-on:click="changeContactView"><i class="ri-windows-fill fa-2x"></i></a>
        <a :class="listView?'text-primary':''" href="#" v-on:click="changeContactView"><i class="ri-list-unordered fa-2x"></i></a>
      </div>
      <div v-show="listView" class="table-responsive">
        <table class="table table-hover">
          <tr>
            <th colspan="2">Name/Abteilung</th>
            <th colspan="2">E-Mail</th>
            <th colspan="2">Telefon</th>
            <th>Vertrieb</th>
            <th>Buchhaltung</th>
            <th>Technik</th>
            <th></th>
            <th></th>
          </tr>
          <tr v-for="(contact, index) in contacts" v-bind:key="index">
            <td colspan="2" v-show="contact.disabled">{{ contact.name }}</td>
            <td colspan="2" v-show="!contact.disabled">
              <input
                  type="text"
                  class="form-control"
                  :id="'name_'+index"
                  placeholder="Name"
                  v-model="contact.name"
                  :disabled="contact.disabled"
                  required
              />
            </td>

            <td colspan="2" v-show="contact.disabled">{{ contact.email }}</td>
            <td colspan="2" v-show="!contact.disabled">
              <input
                  type="email"
                  class="form-control"
                  :id="'email_phone_'+index"
                  placeholder="E-Mail*"
                  v-model="contact.email"
                  :disabled="contact.disabled"
              />
            </td>

            <td colspan="2" v-show="contact.disabled">{{ contact.phone }}</td>
            <td colspan="2" v-show="!contact.disabled">
              <input
                  type="tel"
                  class="form-control"
                  :id="'email_phone_'+index"
                  placeholder="Telefon"
                  v-model="contact.phone"
                  :disabled="contact.disabled"
              />
            </td>

            <td>
              <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="'contact_sales_'+index"
                    name="roles"
                    required
                    :disabled="contact.disabled"
                    :checked="contact.isSales===1"
                    v-on:change="(e)=>contact.isSales = contact.isSales===1?0:1"
                />
                <label
                    class="custom-control-label font-weight-normal"
                    :for="'contact_sales_'+index"
                ></label>
              </div>
            </td>
            <td>
              <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="'contact_billing_'+index"
                    name="roles"
                    required
                    :disabled="contact.disabled"
                    :checked="contact.isBilling===1"
                    v-on:change="(e)=>contact.isBilling = contact.isBilling===1?0:1"
                />
                <label
                    class="custom-control-label font-weight-normal"
                    :for="'contact_billing_'+index"
                ></label>
              </div>
            </td>
            <td>
              <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="'contact_tech_'+index"
                    name="roles"
                    required
                    :disabled="contact.disabled"
                    :checked="contact.isTech===1"
                    v-on:change="(e)=>contact.isTech = contact.isTech===1?0:1"
                />
                <label
                    class="custom-control-label font-weight-normal"
                    :for="'contact_tech_'+index"
                ></label>
              </div>
            </td>
            <td>
              <b-overlay :show="contact.loading">
                <button class="btn btn-outline-primary btn-sm mr-1" v-on:click="(e) => {onSaveContact(e, contact);contact.disabled = false;}">
                  <i class="ri-edit-2-line" v-if="contact.disabled"></i><i v-else class="ri-save-2-line"></i>
                </button>
                <button class="btn btn-outline-danger btn-sm" v-on:click="(e)=>onRemoveContact(e, contact)" v-if="contact.uuid">
                  <i class="ri-delete-bin-5-line"></i>
                </button>
                <a type="button" class="btn btn-outline-dark btn-sm"
                   v-on:click="(e) => {onCloseContact(e, contact);}" v-if="!contact.uuid">
                  <i class="ri-close-line"></i>
                </a>
              </b-overlay>
            </td>
            <td></td>
          </tr>
        </table>
      </div>
      <div v-show="!listView" class="row mt-4">
        <div class="col-md-4 mb-4" v-for="(contact,index) in contacts" v-bind:key="index">
          <b-card>
            <b-overlay :show="contact.loading">
              <b-form class="mr-2">
                <div class="mb-4">
                  <h5 class="d-flex justify-content-between align-items-center">
                    <b><span v-if="!contact.uuid">Neue</span> Ansprechperson</b>
                    <a type="button" class="btn btn-outline text-dark"
                       v-on:click="(e) => {onCloseContact(e, contact);}" v-if="!contact.uuid">
                      <i class="ri-close-line ri-2x"></i>
                    </a>
                    <span class="invisible btn btn-outline text-dark" v-else><i class="ri-close-line ri-2x"></i></span>
                  </h5>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <b>Bereich*</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-12 mb-3">
                    <div class="custom-control custom-checkbox">
                      <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="'billing_'+index"
                          name="roles"
                          required
                          :disabled="contact.disabled"
                          :checked="contact.isBilling===1"
                          v-on:change="(e)=>contact.isBilling = contact.isBilling===1?0:1"
                      />
                      <label
                          class="custom-control-label font-weight-normal"
                          :for="'billing_'+index"
                      >Buchhaltung</label>
                    </div>
                  </div>
                  <div class="col-md-4 col-12 mb-3">
                    <div class="custom-control custom-checkbox">
                      <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="'sales_'+index"
                          name="roles"
                          required
                          :disabled="contact.disabled"
                          :checked="contact.isSales===1"
                          v-on:change="(e)=>contact.isSales = contact.isSales===1?0:1"
                      />
                      <label
                          class="custom-control-label font-weight-normal"
                          :for="'sales_'+index"
                      >Vertrieb</label>
                    </div>
                  </div>
                  <div class="col-md-4 col-12 mb-3">
                    <div class="custom-control custom-checkbox">
                      <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="'tech_'+index"
                          name="roles"
                          required
                          :disabled="contact.disabled"
                          :checked="contact.isTech===1"
                          v-on:change="(e)=>contact.isTech = contact.isTech===1?0:1"
                      />
                      <label
                          class="custom-control-label font-weight-normal"
                          :for="'tech_'+index"
                      >Technik</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group auth-form-group-custom mb-4">
                      <label :for="'name_'+index">Name/Abteilung*</label>
                      <input
                          type="text"
                          class="form-control"
                          :id="'name_'+index"
                          placeholder="Name"
                          v-model="contact.name"
                          :disabled="contact.disabled"
                          required
                      />
                    </div>
                    <div class="form-group auth-form-group-custom mt-2">
                      <label :for="'email_phone_'+index">Erreichbarkeit</label>
                      <input
                          type="tel"
                          class="form-control"
                          :id="'email_phone_'+index"
                          placeholder="Telefon"
                          v-model="contact.phone"
                          :disabled="contact.disabled"
                      />
                      <input
                          type="email"
                          class="form-control mt-2"
                          :id="'email_phone_'+index"
                          placeholder="E-Mail*"
                          v-model="contact.email"
                          :disabled="contact.disabled"
                      />
                    </div>
                  </div>
                  <div class="col-md-12 mt-3">
                    <div class="row">
                      <div class="col-6">
                        <button type="submit" class="btn btn-primary" v-on:click="(e) => {onSaveContact(e, contact);contact.disabled = false;}">
                          <span v-show="contact.disabled">Ändern</span><span v-show="!contact.disabled">Speichern</span>
                        </button>
                      </div>
                      <div class="col-6 text-right">
                        <button class="btn btn-outline-danger btn-sm" v-on:click="(e)=>onRemoveContact(e, contact)" v-if="contact.uuid">
                          <i class="ri-delete-bin-5-line"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </b-overlay>
          </b-card>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<style scoped>
.card{
  background-color: #ebebed!important;
}
.card input{
  background-color: #fff!important;
}
.custom-checkbox .custom-control-label::before{
  background-color: #fff;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00e200;
  background-color: #00e200;
}
</style>