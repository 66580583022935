<script>
import {customerService} from "@/helpers/fakebackend/customer.service";
import {systemService} from "@/helpers/fakebackend/system.service";
import {requestMessage} from "@/helpers/request-message";
import {globalVariable} from "@/helpers/global-variables";
const DATA_TYPE_ENTERPRISE = 0;
const DATA_TYPE_PRIVATE = 1;
export default {
   data() {
    return {
      loading: true,
      globalMessage: {message: "", type: "danger"},
      datatype: 0,
      enterpriseDifferentBilling: false,
      peopleDifferentBilling: false,
      ustdidRequired: false,
      address: {},
      masterData: {},
      billingAddress: {},
      privateCountries: [],
      companyCountries: [],
      selectedCountries: [],
    };
  },
  props: {
    customer: {},
  },
  async created() {
    await this.getCountries();
    await this.getAddresses();
    this.loading = false;
    this.peopleDifferentBilling = !!this.customer.billingEmail;
    this.enterpriseDifferentBilling = !!this.customer.billingAddress;
    this.datatype = this.customer.type;
    //this.datatype = 0;
    this.initMasterData();
    // this.getContacts();
  },
  methods: {
     initMasterData(){
       this.masterData.taxNumber = this.customer.taxNumber;
       this.masterData.billingEmail = this.customer.billingEmail??this.customer.email;
       this.masterData.name = this.customer.name;
       this.masterData.email = this.customer.email;
     },
    async getAddresses(){
      await customerService.getAddress("main").then((data)=>{
        const _address = data.content;
        _address.country = this.getCountryObject(_address.countryCode)
        this.address = _address;
        //  this.address.country = this.getCountryObject(this.address.countryCode);
      });
      await customerService.getAddress("billing").then((data)=>{
        const _address = data.content;
        if(_address){
          _address.country = this.getCountryObject(_address.countryCode)
          this.billingAddress = _address;
        }else {
          this.billingAddress = Object.assign({}, this.address);
          this.billingAddress.uuid = null;
          this.billingAddress.name = this.customer.name;
        }
      });
    },
    async submit(event) {
      event.preventDefault();
      this.loading = true;
      await this.updateAddress(this.address, "main");
      if (this.enterpriseDifferentBilling) {
        if (this.billingAddress.uuid)
          await this.updateAddress(this.billingAddress, "billing");
        else
          await this.addAddress(this.billingAddress, "billing").then((data)=>this.billingAddress.uuid = data.content.uuid);
      }else{
        if (this.billingAddress.uuid) {
          await this.deleteBillingAddress("billing");
          this.billingAddress = {};
        }
      }
      await this.saveMasterData(this.masterData);
      this.loading = false;
    },
    async saveMasterData(address){
      if (this.customer.type === DATA_TYPE_ENTERPRISE){
        address.billingEmail = this.peopleDifferentBilling? address.billingEmail : null;
        return customerService.editCompanyData(address).then(this.updateCustomerInfo).catch(this.handleError);
      }else{
        return customerService.editPrivateData(address).then(this.updateCustomerInfo).catch(this.handleError);
      }
    },
    updateCustomerInfo(data){
       if (data.status === globalVariable.api.SUCCESS_STATUS)
        localStorage.setItem("customer_infos", JSON.stringify(data.content));
    },
    handleError(data){
      this.globalMessage.message = requestMessage[data.code].de;
      this.globalMessage.type = "danger";
    },
    updateAddress(address, purpose=""){
      const _address = Object.assign({}, address);
      _address.country = address.country.code;
      return customerService.updateAddress(_address, purpose).then(()=>{}).catch(this.handleError);
    },
    addAddress(address, purpose=""){
      const _address = Object.assign({}, address);
      _address.country = address.country.code;
      return customerService.addAddress(_address, purpose).catch(this.handleError);
    },
    deleteBillingAddress(purpose=""){
      return customerService.deleteAddress(purpose).then(()=>{}).catch(this.handleError);
    },
    async getCountries(){
      await systemService.getCompanyCountries().then((data)=>{
        this.companyCountries = data.content;
        if (this.datatype === DATA_TYPE_ENTERPRISE)
          this.selectCountries(this.companyCountries);
      });
      await systemService.getPrivateCountries().then((data)=>{
        this.privateCountries = data.content;
        if (this.datatype === DATA_TYPE_PRIVATE)
          this.selectCountries(this.privateCountries);
      })
    },
    changeCountry(country){
      this.ustdidRequired = country.requiresTaxNumber === 1;
    },
    getCountryObject(countryCode){
      for (let i=0; i<this.selectedCountries.length; i++){
        if (this.selectedCountries[i].value.code === countryCode){
          return this.selectedCountries[i].value;
        }
      }
    },
    selectCountries(countries){
      let _countries = [];
      this.address.country = this.billingAddress.country = countries[0];
      this.ustdidRequired = countries[0].requiresTaxNumber === 1;
      countries.forEach(country=>{
        _countries.push({text: country.name, value: country})
      });
      this.selectedCountries = _countries;
    },
  },
};
</script>

<template>
  
  <b-overlay :show="loading" rounded="sm">
    <b-form v-on:submit="submit">
      <b-alert show :variant="globalMessage.type" v-if="globalMessage.message">{{ globalMessage.message }}</b-alert>
      <div class="card">
        <div class="card-body pl-0 ml-1rem">
          <div class="row">
            <div class="col-md-12">
              <h2 class="mb-4"><span v-if="datatype===0">Firmendaten</span><span v-else>Meine Daten</span></h2>
            </div>
            <div class="col-md-5">
              <div class="form-group auth-form-group-custom mb-4">
                <label for="name"><span v-if="datatype===0">Firmenname*</span><span v-if="datatype!==0">Name*</span></label>
                <b-form-input
                    id="name"
                    placeholder="Firmenname*"
                    v-model="address.organization"
                    required
                    type="text"
                ></b-form-input>
              </div>

              <div class="form-group auth-form-group-custom mb-4">
                <label for="street" class="mt-4">Firmenanschrift*</label>
                <b-form-input
                    v-model="address.street"
                    id="street"
                    placeholder="Straße"
                    type="text"
                    required
                ></b-form-input>
              </div>
              <div class="row justify-content-between mb-4">
                <div class="col-5">
                  <b-form-input
                      v-model="address.zipCode"
                      id="zipCode"
                      placeholder="PLZ"
                      type="text"
                      required
                  ></b-form-input>
                </div>
                <div class="col-7">
                  <b-form-input
                      v-model="address.city"
                      id="city"
                      placeholder="Stadt"
                      type="text"
                      required
                  ></b-form-input>
                </div>
              </div>
              <div class="row justify-content-between mb-2">
                <div class="col-6">
                  <div class="form-group auth-form-group-custom ">
                    <b-form-select v-model="address.country" v-on:change="changeCountry" required :options="selectedCountries"></b-form-select>
                  </div>
                </div>
                <div class="col-6" v-if="datatype===0">
                  <div class="form-group auth-form-group-custom mb-4">
                    <b-form-input
                        v-model="masterData.taxNumber"
                        id="taxNumber"
                        placeholder="USt-ID (Pflichtfeld EU Ausland)"
                        type="text"
                        :required="ustdidRequired"
                    ></b-form-input>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="enterpriseDifferentBilling"
                        v-model="enterpriseDifferentBilling"
                    />
                    <label
                        class="custom-control-label"
                        for="enterpriseDifferentBilling"
                    ><span class="font-weight-normal">abweichende Rechnungsanschrift</span></label>
                  </div>
                </div>
              </div>

              <div class="row" v-if="enterpriseDifferentBilling">
                <div class="col-md-12">
                  <div class="form-group auth-form-group-custom mb-2">
                    <label for="differentEnterpriseName"><span v-if="datatype===0">Firmenname</span><span v-if="datatype!==0">Name</span> für Rechnungsstellung</label>
                    <b-form-input
                        id="differentEnterpriseName"
                        placeholder="ausfüllen wenn abweichend"
                        v-model="billingAddress.organization"
                        required
                        type="text"
                    ></b-form-input>
                  </div>

                  <div class="form-group auth-form-group-custom">
                    <label for="enterprisePeople">Ansprechperson</label>
                    <b-form-input
                        v-model="billingAddress.name"
                        id="enterprisePeople"
                        placeholder="ausfüllen wenn abweichend"
                        type="text"
                        required
                    ></b-form-input>
                  </div>
                  <div class="form-group auth-form-group-custom mb-2">
                    <label for="differentStreet" class="mt-4">Rechnungsanschrift*</label>
                    <b-form-input
                        v-model="billingAddress.street"
                        placeholder="Straße"
                        id="differentStreet"
                        type="text"
                        required
                    ></b-form-input>
                  </div>
                  <div class="row justify-content-between mb-2">
                    <div class="col-6 col-md-2">
                      <b-form-input
                          v-model="billingAddress.zipCode"
                          placeholder="PLZ"
                          type="text"
                          required
                      ></b-form-input>
                    </div>
                    <div class="col-6 col-md-4">
                      <div class="form-group auth-form-group-custom ">
                        <b-form-input
                            v-model="billingAddress.city"
                            placeholder="Stadt"
                            type="text"
                            required
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <b-form-select v-model="billingAddress.country" required :options="selectedCountries"></b-form-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-5">
              <div class="form-group auth-form-group-custom mb-4" v-if="datatype===0">
                <label for="contact">Ansprechperson*</label>
                <b-form-input
                    id="contact"
                    placeholder="Ansprechperson"
                    v-model="masterData.name"
                    type="text"
                    required
                ></b-form-input>
              </div>
              <div class="form-group auth-form-group-custom mb-4">
                <label for="email" class="mt-4">E-Mail-Adresse*</label>
                <b-form-input
                    v-model="masterData.email"
                    id="email"
                    placeholder="E-Mail-Adresse*"
                    type="email"
                    required
                ></b-form-input>
              </div>
              <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="peopleDifferentBilling"
                    v-model="peopleDifferentBilling"
                />
                <label
                    class="custom-control-label"
                    for="peopleDifferentBilling"
                ><span class="font-weight-normal">abweichende Rechnungs-E-Mail</span></label>
              </div>
              <div class="form-group auth-form-group-custom" v-if="peopleDifferentBilling">
                <label for="billingEmail" class="mt-4">E-Mail-Adresse für Rechnungen*</label>
                <b-form-input
                    v-model="masterData.billingEmail"
                    id="billingEmail"
                    placeholder="E-Mail-Adresse*"
                    type="email"
                    required
                ></b-form-input>
              </div>
            </div>

            <div class="col-md-12">
              <button class="btn btn-primary mt-2"><span v-if="!loading">Speichern</span><span v-if="loading">Warten...</span></button>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
