<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import BaseData from "./Components/base_data";
import PaymentMethod from "@/views/pages/customer_base_data/Components/PaymentMethod";
import ContactPerson from "@/views/pages/customer_base_data/Components/ContactPerson";
import {customerService} from "@/helpers/fakebackend/customer.service";

/**
 * Form-element component
 */
const SEPA_PAYMENT_TYPE = "sepa";
const STRIPE_PAYMENT_TYPE = "stripe";
export default {
  page: {
    title: "Kundenstammdaten",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {ContactPerson, PaymentMethod, Layout, PageHeader, BaseData},
  data() {
    return {
      title: "Kundenstammdaten",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Kundenstammdaten",
          active: true,
        },
      ],
      status: "not_accepted",
      checkCustom: "not_accepted",
      checked: true,

      userData: {},
      customer: {},
      paymentMethod: {
        iban: null,
        owner: null,
      },
      isLoading: true,
      isBankInfoSubmitting: false,
    };
  },
  beforeCreate() {
  },
  created() {
    this.initCustomer();
    this.initInfoBarEvents();
  },
  methods: {
    initInfoBarEvents(){
      this.$root.$on("popup_address_added", (customer)=>{
        this.customer = customer;
      });
      this.$root.$on("popup_payment_added", (customer)=>{
        this.customer = customer;
        this.getBankInfos();
      });
    },
    async getBankInfos(){
      if (this.customer.paymentMethod === 0){
        customerService.getCreditcardPayment().then((data)=>{
          const creditcardPayment = data.content;
          if (creditcardPayment.number){
            this.paymentMethod.iban = creditcardPayment.number;
            this.paymentMethod.owner = creditcardPayment.owner;
            this.paymentMethod.validUntil = creditcardPayment.validUntil;
            this.paymentMethod.type = STRIPE_PAYMENT_TYPE;
          }
        });
      }else{
        customerService.getSepaPayment().then((data)=>{
          this.paymentMethod = {}
          const payment = data.content;
          if (payment.referenceNumber){
            this.paymentMethod.iban = payment.accountNumber;
            this.paymentMethod.owner = payment.owner;
            this.paymentMethod.signDate = payment.signDate;
            this.paymentMethod.referenceNumber = payment.referenceNumber;
            this.paymentMethod.type = SEPA_PAYMENT_TYPE;
          }
        });
      }
    },
    getRegistrationInfos(){
      return customerService.getRegistrationInfos().then(()=>{
        /*if(!data.content || data.content.status === 0)
          this.$router.push("/");
        */
      });
    },
    initCustomer(){
      const user = JSON.parse(localStorage.getItem("user_infos"));
      customerService.getCustomer(user.customerUuid).then(async (data)=>{
        this.customer = data.content;
        localStorage.setItem("customer_infos", JSON.stringify(this.customer));
        // await this.getRegistrationInfos();
        this.getBankInfos();
        this.isLoading = false;
        this.openAddressPopupIfCustomerDataNotExist();
      });
    },
    updateCustomer(customer){
      this.customer = customer;
    },
    ibanHidedFormat(iban){
      return iban.slice(0, 4) + " **** **** **** " + iban.slice(-4);
    },
    updateBankInfos(data){
      this.$bvModal.hide("modal-payment");
      // const paymentMethodCopy = Object.assign({}, this.paymentMethod);
      // paymentMethodCopy.owner = data.name;
      //paymentMethodCopy.iban = this.ibanHidedFormat(data.iban);
      // paymentMethodCopy.type = data.type;
      // this.paymentMethod = paymentMethodCopy;
      this.paymentMethod.owner = data.owner;
      if (data.type === SEPA_PAYMENT_TYPE){
        this.paymentMethod.iban = this.ibanHidedFormat(data.accountNumber);
        this.paymentMethod.signDate = data.signDate;
        this.paymentMethod.referenceNumber = data.referenceNumber;
        this.paymentMethod.type = SEPA_PAYMENT_TYPE;
      }else{
        this.paymentMethod.iban = this.ibanHidedFormat(data.number);
        this.paymentMethod.validUntil = data.validUntil;
        this.paymentMethod.type = STRIPE_PAYMENT_TYPE;
      }
      this.$forceUpdate();
    },
    openAddressPopup(e){
      e.preventDefault();
      document.querySelector("#rx-info-bar-message").click();
    },
    openAddressPopupIfCustomerDataNotExist(){
      if (!this.customer.address)
        this.openAddressPopup(new Event("click"));
    },
  }
};
</script>

<template>
  <Layout>
    <b-overlay :show="isLoading" rounded="sm">
      <PageHeader :title="title" :items="items" />
      <BaseData v-if="!isLoading && customer.type!==null" :customer="customer" v-on:update="updateCustomer" />
    </b-overlay>

    <b-overlay :show="isLoading" rounded="sm" v-if="customer.address">
      <div class="row">
        <div class="col-md-12 pl-4 pr-4">
          <div class="underline-primary mb-4"></div>
          <h2>Zahlungsarten</h2>
          <div class="row">
            <div class="col-md-6">Wählen Sie zwischen Kreditkarte und SEPA-Lastschrift. Sobald Sie Ihre Zahlungsdaten hinterlegt haben, können Sie direkt den vollen Leistungsumfang von kyberio nutzen.</div>
          </div>
          <div class="mt-4" v-if="!paymentMethod.owner && customer.address"><b-button v-on:click="openAddressPopup" class="btn btn-primary btn-plus"><span><i class="dripicons-plus"></i></span>hinzufügen</b-button></div>

          <div class="row" v-if="paymentMethod.owner">
            <div class="col-md-5">
              <div class="mb-4 mt-4">
                <div v-if="paymentMethod.type==='sepa'">
                  <div><b>Sepa-Lastschrift-Mandat</b></div>
                  <div>erteilt am {{ paymentMethod.signDate }} | Mandatsreferenz {{ this.paymentMethod.referenceNumber }}</div>
                </div>
                <div v-else>
                  <div><b>Kreditkarte</b></div>
                  <div>Gültig bis {{ paymentMethod.validUntil }}</div>
                </div>
              </div>
              <div class="form-group auth-form-group-custom">
                <label for="owner">Konto-Inhaber*</label>
                <b-form-input
                    id="owner"
                    placeholder="Konto-Inhaber*"
                    v-model="paymentMethod.owner"
                    required
                    type="text"
                ></b-form-input>
              </div>
              <div class="form-group auth-form-group-custom ">
                <label for="iban"><span v-if="paymentMethod.type==='sepa'">IBAN*</span><span v-else>Kartennummer</span></label>
                <b-form-input
                    id="iban"
                    v-model="paymentMethod.iban"
                    required
                    type="text"
                ></b-form-input>
              </div>
              <div>
                <button class="btn btn-primary pr-3 pl-3" v-b-modal.modal-payment><span v-if="!isBankInfoSubmitting">Ändern</span><span v-if="isBankInfoSubmitting">Warten...</span></button>
              </div>
            </div>
          </div>

          <b-modal id="modal-payment" size="xl" hide-footer>
            <div class="pl-4 pr-4">
              <h2 class="mb-5">Zahlungsmethode hinzufügen</h2>
              <PaymentMethod :payment-method="paymentMethod" v-on:submitted="updateBankInfos"></PaymentMethod>
            </div>
          </b-modal>
        </div>
      </div>
    </b-overlay>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="row">
        <div class="col-md-12 pl-4 pr-4">
          <div class="underline-primary mb-4 mt-4"></div>
          <h2>Ansprechperson</h2>
          <div class="row">
            <div class="col-md-6">Für die Optimierung der Abläufe können Sie an dieser Stelle die relevanten Ansprechpersonen hinterlegen. Falls Sie diesen Bereich leer lassen, sind Sie unsere Ansprechperson für Wartungsarbeiten, Rechnungsstellung etc.</div>
          </div>
          <ContactPerson></ContactPerson>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>
