import {authJsonHeader} from "@/helpers/fakebackend/auth-header";
import {globalVariable} from "@/helpers/global-variables";
import {userService} from "@/helpers/fakebackend/user.service";

export const contactService = {
    get,
    getAll,
    addContact,
    updateContact,
    deleteContact,
};


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/contact`, requestOptions).then(handleResponse);
}

function get(purpose="tech") {
    const requestOptions = {
        method: 'GET',
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/contact/${purpose}`, requestOptions).then(handleResponse);
}

function addContact(contact) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify(contact),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/contact`, requestOptions).then(handleResponse);
}

function updateContact(contact) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify(contact),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/contact/${contact.uuid}`, requestOptions).then(handleResponse);
}

function deleteContact(contact) {
    const requestOptions = {
        method: 'DELETE',
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/contact/${contact.uuid}`, requestOptions).then(handleResponse);
}

async function handleResponse(response) {
    if (response.ok) {
        return response.json();
    }else{
        if (response.status === 401) {
            userService.logout();
        }
        if (response.status >= 500) {
            throw {code: globalVariable.api.ERROR_500};
        }
        throw await response.json();
    }
}